import { useState } from 'react';
import styles from './../css/Login.module.css';
import Api from "../helperFunctions/Api";
import Loading from './loading';
import Modal from "./modal";
import {useNavigate, useParams} from "react-router-dom";
import InputField from './InputField';
import { setUserResult2 } from '../Redux/Reducers/resultReducer';
import {useDispatch} from 'react-redux';

const Login = () => {
    const [modalToggle, setModalToggle] = useState(false);
    const [modalText, setModalText] = useState("");
    const { d } = useParams();
    const [showLoading, setShowLoading] = useState(false);
    const [validUser, setValidUser] = useState(false);
    const [userPass, setUserPass] = useState(false);
    // const [orgID, setOrgID] = useState(d?true:false);
    // const [defOrg, setDefOrg] = useState(d);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [inputs, setInputs] = useState({});
    const onInputChange = (e = this) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputs(inputs => ({ ...inputs, [name]: value }));
        if (name === 'user-con') {
            (/^\s*[\w\-+_]+(\.[\w\-+_]+)*@[\w\-+_]+\.[\w\-+_]+(\.[\w\-+_]+)*\s*$/.test(value) || /^\+*\d{1,3}\d{9,10}$/.test(value)) ?
                setValidUser(true) : setValidUser(false);
        }

        if (name === 'user-pass') {
            value.length >= 6 ?
                setUserPass(true) : setUserPass(false);
        }

        // if (name === 'org-id') {
        //     setDefOrg(value)
        //     value.length >= 4 ?
        //         setOrgID(true) : setOrgID(false);
        // }
    };

    const onSubmit = (e = this) => {
        e.preventDefault();
        
        if(!Api.checkNetwork()) return;

        setShowLoading(true);
        const payload = {
            email: inputs["user-con"],
            pass: inputs["user-pass"],   
        }
        if(d) payload.institution_id = d;
        Api.PostApi('/result/get-user-results', payload )
        .then((result) => {
               setShowLoading(false);
                setModalText(result.message)
                console.log(result,'result');
                if (result.isSuccessful || result.isSuccesful) {
                    localStorage.setItem('eduplus-user88', JSON.stringify(result.data));
                    dispatch(setUserResult2(result.data));
                    localStorage.setItem('edures_em', inputs["user-con"])
                    navigate('/user-results');
                }
            })
            .catch(err => {
                setModalText('Error, Please check your network and try again')
            })
            .finally(()=>{
                setShowLoading(false);
                setModalToggle(true);
            });
    };

    return (
        <>
            <Loading shouldShow={showLoading} />
            <Modal modalObject={{ header: "", footer: "", body: modalText }} modalTogglee={modalToggle} closeModal={() => setModalToggle(false)} />
            <div className={`${styles.formParent}`}>

                <form className={`${styles.defaultLog}`} onSubmit={onSubmit}>
                    <h1 className={`${styles.signIn}`}>
                        {`Log In`}
                    </h1>
                    <InputField title={`${d ? `Email/User Id`: `Email`}`} inputID='user-con' placeholder='Enter your Email' type='text' onChange={onInputChange} />
                    <InputField title='Password' inputID='user-pass' placeholder='Enter Password' type='password' onChange={onInputChange} />
                    <button type='submit' className={`${styles.submitButton}`} disabled={ !(validUser && userPass) } title={ !(validUser && userPass) ? 'Please fill out all fields' : 'Login'}>Log In</button>
                </form>
            </div>
        </>
    );
};

export default Login;