let op = "2+2*2/2+2*7";

const allSymbols = {
    "/": 1,
    "*": 1,
    "+": 1,
    "-": 1
}

const specialSymbols = {
    ">": 1,
    "<": 1,
    "<=":1,
    ">=":1,
    "=": 1,
    "!=": 1,
    "&":1,
    "|":1
}

/**
 * Check for curly bracket
 * Check for bracket
 */

const performOperation = (num1, num2, op)=>{
    let result = 0;
    switch(op){
        case "/":
            result = num1 / num2;
            break;
        case "*":
            result = num1 * num2;
            break;
        case "+":
            result = num1 + num2;
            break;
        case "-":
            result = num1 - num2;
            break;
    }
    return result.toString();
}

const performLogicOperation = (num1, num2, op)=>{
    let result = 0;
    switch(op){
        case ">":
            result = num1 > num2 ? 1 : 0
            break;
        case ">=":
            result = num1 >= num2 ? 1 : 0;
            break;
        case "<":
            result = num1 < num2 ? 1 : 0;
            break;
        case "<=":
            result = num1 <= num2 ? 1 : 0;
            break;
        case "=":
            result = num1 == num2 ? 1 : 0;
            break;
        case "!=":
            result = num1 != num2 ? 1: 0;
            break;
        case "&":
            result = num1 && num2;
            break;
        case "|":
            result = num1 || num2;
            break;
    }
    console.log(result,'gee');
    return result.toString();
}

const checkSymbol = (symbol,pos, text)=>{
    let start = 0; let end = text.length;

    let i = 1;

    while((pos - i) > start){
        if(allSymbols[`${text[pos - i]}`]) start = pos -i +1; 
        i++;
    }
    let firstNumber = Number(text.slice(start, pos));
    i = 1;
    while((pos + i) < end){
        if(allSymbols[`${text[pos + i]}`]) end = pos + i;
        i++;
    }
    let secondNumber = Number(text.slice(pos + 1, end));
    text = text.slice(0, start) + performOperation(firstNumber, secondNumber, symbol) + text.slice(end, text.length);
    return text;
}

const checkForDoubleSymbol = (symbol,pos,pos2, text)=>{
    let start = 0; let end = text.length;

    let i = 1;

    while((pos - i) > start){
        if(specialSymbols[`${text[pos - i]}`]) start = pos -i +1; 
        i++;
    }
    let firstNumber = Number(text.slice(start, pos));
    i = 1;
    while((pos2 + i) < end){
        if(specialSymbols[`${text[pos2 + i]}`]) end = pos2 + i;
        i++;
    }
    let secondNumber = Number(text.slice(pos2 + 1, end));
    //console.log(symbol,"start")
    text = text.slice(0, start) + performLogicOperation(firstNumber, secondNumber, symbol) + text.slice(end, text.length);
    //console.log(text, 'tx')
    return text;
}

const insertOperation = (text)=>{
    const sym = Object.keys(allSymbols);
    for(let i = 0; i < sym.length; i++){
        let isFound = true;
        while(isFound){
            isFound = false;
            for(let j = 0; j < text.length; j++){
                if(text[j] == sym[i]){
                    text = checkSymbol(sym[i], j, text);
                    console.log(text,`${j} ${i}`);
                    isFound = true;
                }
            }
        }
        
    }
    return text;
}

export const insertOperationForDoubleSymbol = (text)=>{
    let isFound = true;
    while(isFound){
        isFound = false;
        for(let j = 0; j < text.length; j++){
            if(specialSymbols[`${text[j]}`] && specialSymbols[`${text[j+1]}`]){
                text = checkForDoubleSymbol(text[j] + text[j+1], j, j+1, text);
                isFound = true;
            }
            else if(specialSymbols[`${text[j]}`]){
                text = checkForDoubleSymbol(text[j], j, j, text);
                isFound = true;
            }
        }
    }
    return text;
}

const checkBracket = (text, isCondition)=>{
    let stack = []; let start = 0;
    do{
        stack = [];
        for(let i = 0; i < text.length; i++){
            if(text[i] == '(')stack.push(i);
            if(text[i] == ')'){
                start = stack.pop();
                let nTxt = isCondition ? insertOperationForDoubleSymbol(text.slice(start + 1, i)) : insertOperation(text.slice(start + 1, i));
                text = text.slice(0, start) + nTxt + text.slice(i+1, text.length);
            }
        }
        //stack = []
    }
    while(stack.length > 0)
    return isCondition? insertOperationForDoubleSymbol(text) : insertOperation(text);
}
export const filterTextUsingSpace = (text)=>{
    return text.split(' ').join('')
}
export const filterTextUsingSymbol = (text, isCondition)=>{
    const newText = [];
    for(let i = 0; i < text.length; i++){
        switch(text[i]){
            case '(':
                if(!allSymbols[`${text[i-1]}`])
                    newText.push('*');
                newText.push(text[i]);
                break;
            case '-':
                if(text[i - 1] == '-'){
                    newText.pop();
                    newText.push('+');
                }
                else if(text[i - 1] == '+'){
                    newText.pop();
                    newText.push('-');
                }              
                else newText.push(text[i])
                break;
                case '+':
                    if(text[i - 1] == '-'){
                        newText.pop();
                        newText.push('-');
                    }
                    else if(text[i - 1] == '+'){
                        newText.pop();
                        newText.push('+');
                    }
                    else newText.push(text[i]);
                break;
            default:
                newText.push(text[i])
        }
    }
    return checkBracket(newText.join(''), isCondition);
}

 /*
  {0:0:1}
*/
const checkStructure = (structure,posObject,result) =>{
    let eachStruct = structure.split(':');
    let section = eachStruct[0] == 'S' ? posObject.section : Number(eachStruct[0])-1;
    let row = eachStruct[1] == 'R' ? posObject.row : Number(eachStruct[1])-1;
    let column = eachStruct[2] == 'C' ? posObject.column : Number(eachStruct[2])-1;
    //console.log(structure,'str')
    return Number(result[section][row][`${column}`])
}
//{S:R:0}*{S:R:1}
export const checkCurlyBracket = (text, posObject, result, isCondition=false)=>{
    text = filterTextUsingSpace(text);
    let stack = []; let start = 0;
        for(let i = 0; i < text.length; i++){
            if(text[i] == '{')stack.push(i);
            if(text[i] == '}'){
                start = stack.pop();
                text = text.slice(0, start) + checkStructure(text.slice(start + 1, i), posObject, result) + text.slice(i+1, text.length);
                i=0;
                //console.log(text,'formula')
            }
        }
    return filterTextUsingSymbol(text, isCondition);
}


/*const result = {
    '0':[{}],
    '1':[{}],
    '2':[{
        '0': 'Phy111',
        '1': '10',
        '2': '5',
        '3': '15',
        '5': '50'
    }]
}

console.log(checkCurlyBracket('{S:R:4}>10',{
    section: '2', row: 0, column: 5
}, result, true));*/