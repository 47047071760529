import './css/App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from "./pages/MainLogin";
// import UserResult from './pages/Results';
import { Provider } from 'react-redux';
import store from './Redux/store';
import Certificate from "./pages/Certificate";
import UserResult2 from './pages/UserResult';

function App() {

  return (
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/account/:d" element={<Login />} />
            <Route path="/result/:d" element={<Certificate />} />
            <Route path="/user-results/:d" element={<Login />} />
            <Route path="/user-results/:d/*" element={<Login />} />
            <Route path="/user-results" element={<UserResult2 />} />
          <Route path="/:d" element={localStorage['eduplus-user88'] ? <UserResult2 /> : <Login />} />
          <Route path="*" element={localStorage['eduplus-user88'] ? <UserResult2 /> : <Login />} />
          </Routes>
        </Router>
      </Provider>
  );
}

export default App;