import LoginCom from '../components/Login';
import styles from './../css/MainLogin.module.css';
import { Link, useParams } from 'react-router-dom';
import LogoImg from '../components/Logo_img';
import { setCustomizeData } from '../Redux/Reducers/resultReducer';
import { useMemo } from 'react';
import Api from '../helperFunctions/Api';
import { useDispatch, useSelector } from 'react-redux';

const Login = () => {
    const { d } = useParams();
    const dispatch = useDispatch();
    const customization = useSelector(store => store.results.customization);

    useMemo(() => {
        if (d && d > 10) { //alert('goo')
            Api.PostApi('/form/get-customization', { service_id: 4, institution_id: d }, true).then(result => {
                if (result.isSuccessful) {
                    localStorage.setItem('customization', result.data.customization);
                    dispatch(setCustomizeData(JSON.parse(result.data.customization)));
                }
            });
            localStorage.customization && dispatch(setCustomizeData(JSON.parse(localStorage.customization)));
        } else {
            localStorage.removeItem('customization');
            dispatch(setCustomizeData(null));
        }
    }, [d, dispatch]);

    return (
        <div className={`${styles.main}`} style={customization && customization.style}>
            <div className={`${styles.navDiv}`}>
                <div className={`${styles.nav}`} >
                    <Link to='/'><LogoImg width='100' customization={customization} /></Link>
                </div></div>
            <div className={`${styles.heroDiv}`} style={customization && { backgroundImage: `URL(${customization.bgUrl})` }}></div>
            <div className={`${styles.loginDiv}`}><LoginCom /> </div>
            <div className={`${styles.footer}`}>Copyright &copy; 2024 Sight-EduPlus</div>
        </div>
    );
};
export default Login;