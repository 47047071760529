import { createSlice } from "@reduxjs/toolkit";

export const resultSlice = createSlice({
    name: 'results',
    initialState: {
        userResult:{}, customization: null,
    },
    reducers: {
        setUserResult2: (state, action)=>{
            state.userResult = action.payload;
        },
        setCustomizeData: (state, action) => {
            state.customization = action.payload;
        },
    }
})

export const {
    setUserResult2, setCustomizeData
} = resultSlice.actions

export default resultSlice.reducer;