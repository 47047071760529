import styles from './../css/InputField.module.css';

const InputField = ({ title, inputID, placeholder, type, onChange, tooltip = title, value }) => {
    return (
        <>
            <label className={`${styles.inputLabel}`} htmlFor={inputID}><span className={`${styles.text}`}>{title}<span className={`${styles.asterisk}`}>*</span></span>
                <input value={value} type={type} name={inputID} className={`${styles.formInput}`} id={inputID} placeholder={placeholder} onInput={onChange} title={tooltip} />
            </label>
        </>
    );
};

export default InputField;