import React from 'react';
import logo from '../images/edu-logo.png';
import logoCss from '../css/logo.module.css';

const Logo_img = ({ width, smallScreen, customization }) => {
  const alt = "logo";
  
  return (
    <>
      <span className={logoCss.logo_container}>
        <img src={customization?.logoUrl || logo} alt={alt} width={width} />
        {smallScreen ? "" : <span>{customization?.displayName || 'EDUPLUS RESULT'}</span>}

      </span></>
  );
};

Logo_img.defaultProps = {
  width: 35,
};

export default Logo_img;