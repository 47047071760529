class Api{
    //Set domain name here
    static domain = "https://api.sight-eduplus.com";
    //static domain = "http://localhost:8000"
    static domainState = false;

    static checkNetwork(){
        if(!navigator.onLine){alert("You are offline"); return false}
        return true;
    }

    static PostApi(url, requestObject, shouldAddAuthorization=false, isDomainUsed=Api.domainState){      
        if(!isDomainUsed){
            url = Api.domain + url;
        }
       // console.log(requestObject)
        return new Promise((resolve,reject)=>{
            fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                headers: shouldAddAuthorization ? {
                    'Authorization': 'Bearer ' + localStorage.getItem('eduplus_tkn'),
                    'Content-Type': 'application/json'
                } : {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestObject)
            })
            .then(Response=>Response.json())
            .then(result=>{
                //console.log(result)
                if(!result.isSuccessful && result.status === 403){
                    localStorage.removeItem('eduplus_tkn');
                    alert("Authentication failed. Please re-login.")
                    window.location.href = "/account/login";
                }
                else resolve(result)
            })
            .catch(error=>{
                console.log(error,'this error')
                resolve({
                    isSuccessful: false,
                    message:"Connectivity error. Please check internet network and try again"
                });
            })
        }) 
    }
    static getApi(url,authorizationString=false,isDomainUsed = Api.domainState){
        if(!isDomainUsed){
            url = Api.domain + url;
        }

        return new Promise((resolve,reject)=>{
            fetch(url,{
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
                headers: authorizationString ? {
                    'Authorization': 'Bearer ' + localStorage.getItem('eduplus_tkn'),
                }: {}
            })
            .then(Response=>Response.json())
            .then(result=>{
                //console.log(result)
                if(!result.isSuccessful && result.status === 403){
                    localStorage.removeItem('eduplus_tkn');
                    alert("Authentication failed. Please re-login.")
                    window.location.href = "/account/login";
                }
                else resolve(result)
            })
            .catch(error=>{
                console.log(error,'this error')
                alert("Connectivity error. Please check internet network and try again")
                resolve({
                    isSuccessful: false,
                    message:"Connectivity error. Please check internet network and try again"
                });
            })
        })
    }
    static PostFormData(url, requestObject, shouldAddAuthorization=false, isDomainUsed=Api.domainState){

        if(!isDomainUsed){
            url = Api.domain + url;
        }

        return new Promise((resolve,reject)=>{
            let xhhtp = new XMLHttpRequest();
            xhhtp.onreadystatechange = function(){   
                //console.log(`state: ${this.readyState} | status: ${this.status}`)               
                if (this.readyState === 4 && this.status === 200){
                    let res = JSON.parse(xhhtp.responseText)
                    //console.log(res)  
                        resolve(res)                          
                }
                else if(this.status === 500 || this.status === 403){
                    reject({
                        isSuccessful: false
                    })
                }
            }
            xhhtp.open("POST", url, true); 
            if(shouldAddAuthorization){
                xhhtp.setRequestHeader("Authorization", "Bearer " + localStorage.getItem('eduplus_tkn'));
            }
            xhhtp.send(requestObject);
        })

    }

    static PostFormData2(url, requestObject, shouldAddAuthorization=false, isDomainUsed=Api.domainState){
        if(!isDomainUsed){
            url = Api.domain + url;
        }

        return fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: shouldAddAuthorization ? {
                'Authorization': 'Bearer ' + localStorage.getItem('eduplus_tkn'),
                'Content-Type': 'multipart/form-data'
            } : {
                //'Content-Type': 'application/json'
            },
            body: requestObject
        });

        /*return new Promise((resolve,reject)=>{
            let xhhtp = new XMLHttpRequest();
            xhhtp.onreadystatechange = function(){                  
                if (this.readyState === 4 && this.status === 200){
                    let res = JSON.parse(xhhtp.responseText)
                    //console.log(res)  
                    if(res.isSuccessful){
                        resolve(res)                        
                    }    
                }
                else if(this.status === 403){
                    resolve({
                        isSuccessful: false
                    })
                }
            }
            xhhtp.open("POST", url, true); 
            if(shouldAddAuthorization){
                xhhtp.setRequestHeader("Authorization", "Bearer " + localStorage.getItem('eduplus_tkn'));
            }
            xhhtp.send(requestObject);
        })*/

    }
}
export default Api;

export const dateObject = {
    month:{
        0:"Jan",
        1:"Feb",
        2:"Mar",
        3:"Apr",
        4:"May",
        5:"Jun",
        6:"Jul",
        7:"Aug",
        8:"Sep",
        9:"Oct",
        10:"Nov",
        11:"Dec"
    },
    day:{
        1:"st",
        2:"nd",
        3:"rd"
    }
}

export const convertDatetoString = (arg,time) =>{
    let date = arg? new Date(arg) : new Date();
    
    const month = dateObject.month[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hour = date.getHours() > 12?date.getHours()-12:date.getHours();
    const minutes = date.getMinutes()<10?`0${date.getMinutes()}`:date.getMinutes();
    const period = date.getHours() > 12 ? 'PM':date.getHours() === 12 && date.getMinutes() > 0?'PM':'AM';

    let dayString = "th";
    if(day<=9){
        dayString = dateObject.day[day]?dateObject.day[day]:"th";
    }
    else if(day > 20){
        let tempDay = day%10;
        dayString = dateObject.day[tempDay]?dateObject.day[tempDay]:"th";
    }
    
    return time === null?`${day}${dayString} ${month}, ${year}`:`${day}${dayString} ${month} , ${year} | ${hour}:${minutes} ${period}`;

}

export const convertDatetoInputValue = (arg,time) =>{
    let d = new Date(arg);
    let month = d.getMonth()+1 < 10? `0${d.getMonth()+1}`:d.getMonth()+1;
    let day = d.getDate() < 10 ? `0${d.getDate()}`:d.getDate();
    let year = d.getFullYear();
    let hour = d.getHours()<10 ? `0${d.getHours()}`:d.getHours();
    let min = d.getMinutes()<10 ? `0${d.getMinutes()}`:d.getMinutes();

    return time? `${hour}:${min}`:`${year}-${month}-${day}`;
}